var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:['backDrop', _vm.isPermissionTypeModal ? 'isShow' : ''],on:{"click":function($event){return _vm.toggle(false)}}}),_c('div',{class:['permissionTypeModal', _vm.isPermissionTypeModal ? 'isShow' : '']},[_c('div',{staticClass:"modalHeader"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('img',{attrs:{"src":require('@/assets/icon-close-permission.svg'),"alt":""},on:{"click":function($event){return _vm.toggle(false)}}})]),_c('div',{staticClass:"permission-type-modal"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":{
          pageSize: 50,
          hideOnSinglePage: true,
        }},scopedSlots:_vm._u([{key:"admin",fn:function(text, key){return _c('span',{},[(
              key.key != 'permission-type-member' &&
              key.key != 'permission-type-setting' &&
              key.key != 'permission-type-smart-assistant'
            )?_c('img',{attrs:{"src":require("@/assets/images/checktrue.svg"),"alt":""}}):_vm._e(),(
              key.key == 'permission-type-member' &&
              key.key == 'permission-type-setting' &&
              key.key == 'permission-type-smart-assistant'
            )?_c('span'):_vm._e()])}},{key:"operator",fn:function(text, key){return _c('span',{},[(
              key.key == 'permission-type-member-levels' ||
              key.key == 'permission-type-import-member'
            )?_c('span',[_c('img',{attrs:{"src":require("@/assets/images/checkfalse.svg"),"alt":""}})]):(
              key.key == 'permission-type-member' ||
              key.key == 'permission-type-smart-assistant' ||
              key.key == 'permission-type-setting'
            )?_c('span'):_c('span',[_c('img',{attrs:{"src":require("@/assets/images/checktrue.svg"),"alt":""}})])])}},{key:"staff",fn:function(text, key){return _c('span',{},[(
              key.key == 'permission-type-login-merchant' ||
              key.key == 'permission-type-tag'
            )?_c('span',[_c('img',{attrs:{"src":require("@/assets/images/checktrue.svg"),"alt":""}})]):(
              key.key == 'permission-type-member' ||
              key.key == 'permission-type-smart-assistant' ||
              key.key == 'permission-type-setting'
            )?_c('span'):_c('span',[_c('img',{attrs:{"src":require("@/assets/images/checkfalse.svg"),"alt":""}})])])}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }