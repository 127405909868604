<template>
  <div class="container-fluid pb-permission">
    <Header />
    <Breadcrumbs
      v-if="false"
      :title="currenttitle"
      :parent="parent"
      :current="current"
    />
    <div class="mx-auto content-outer-container">
      <div class="mx-auto mainContainer">
        <div class="titleContainer">
          <div class="title">
            <div class="textTitle">
              <img
                class="mr-2"
                style="vertical-align: sub"
                src="@/assets/images/user_permission.svg"
                alt=""
              />{{ lbl['user-permissions-title'] }}
            </div>
            <div class="text-label chip">{{ allUser }}</div>
          </div>
          <div class="search d-flex">
            <div class="searchContainer">
              <form autocomplete="off">
                <a-input
                  type="text"
                  class="searchInput"
                  :placeholder="lbl['user-permissions-search-placeholder']"
                  v-model="filterNameEmail"
                  @keyup.enter="onEnterInput($event)"
                  autocomplete="off"
                />
              </form>
              <img
                @click="onEnterInput($event)"
                :src="require('@/assets/images/Search.png')"
                alt=""
              />
            </div>
            <a-button
              @click="toggleUserModal(true, 'create')"
              class="searchBtn d-flex"
            >
              <img :src="require('@/assets/images/Add-user.png')" alt="" />{{
                lbl['user-permissions-add-newuser']
              }}
            </a-button>
          </div>
        </div>
        <div class="subTitle">
          {{ lbl['user-permissions-store-name'] }}:
          {{ storeName ? storeName : '-' }} |
          {{ lbl['user-permissions-brand-id'] }}: {{ brandId ? brandId : '-' }}
        </div>
        <hr />
        <div class="filterContainer nopad">
          <div :class="['filterMain', showFilterMain ? 'active' : '']">
            <div class="filter" @click="toggle('showFilterMain')">
              {{ lbl['user-permissions-filter'] }}
              <img :src="require('@/assets/images/filter_list.png')" alt="" />
            </div>
            <div class="spaceHover"></div>
            <div class="filterDropdown">
              <div class="filterLeft">
                <div
                  :class="['filterList', item === subFilter ? 'active' : '']"
                  v-for="item in Object.keys(filter)"
                  @click="changeSubFilter(item)"
                  :key="item"
                >
                  {{ filter[item].label }}
                  <img
                    :src="require('@/assets/images/navigate_next.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="filterRight">
                <div v-for="item in filter[subFilter].input" :key="item">
                  <div
                    v-if="filter[subFilter].type === 'checkbox'"
                    class="filterList"
                    @click="selectFilter(item)"
                  >
                    <CheckBox
                      :label="item"
                      :valuee="item"
                      :check="filter[subFilter].value"
                      type="filter"
                    />
                  </div>
                  <div
                    v-if="filter[subFilter].type === 'text'"
                    class="filterList input"
                  >
                    <input
                      type="text"
                      :placeholder="item"
                      v-model="filter[subFilter].value"
                    />
                  </div>
                </div>
              </div>
              <div class="btnFilter">
                <div @click="resetFilter()">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="getUserListByFilter()">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="filterItem"
            v-for="(item, index) in Object.keys(allFilter)"
            :key="'filter' + index"
          >
            {{ allFilter[item]
            }}<img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
          <div class="ml-auto">
            <a-button
              class="btn-permission"
              @click="togglePermissionTypeModal()"
              ><span>
                <img
                  class="mr-2"
                  src="@/assets/images/setting_permission.svg"
                  alt=""
                /> </span
              >{{ lbl['permission-type-btn'] }}</a-button
            >
          </div>
        </div>
        <div class="roleContainer">
          <div
            class="roleItem"
            v-for="item in Object.keys(roleData)"
            :key="roleData[item].header"
          >
            <div class="roleHeader">
              <div class="headerTab">
                <div class="title">
                  <div class="textTitle">{{ roleData[item].header }}</div>
                  <div class="text-label chip">{{ totalList(item) }}</div>
                </div>
                <!-- <button @click="togglePermissionModal(true, item)">
                  <img :src="require('@/assets/images/cod001.png')" />
                </button> -->
              </div>
              <!-- <p
                v-if="item === 'admin'"
                :class="['roleSubHeader', isShowmore[item] ? 'showmore' : '']"
              >
                {{ permissionadmin }}
                <span v-if="!isShowmore[item]" @click="showMore(item, true)">{{
                  lbl['user-permissions-see-more']
                }}</span>
                <span v-if="isShowmore[item]" @click="showMore(item, false)">{{
                  lbl['user-permissions-see-less']
                }}</span>
              </p>
              <p
                v-if="item === 'operator'"
                :class="['roleSubHeader', isShowmore[item] ? 'showmore' : '']"
              >
                {{ permissionoperator }}
                <span v-if="!isShowmore[item]" @click="showMore(item, true)">{{
                  lbl['user-permissions-see-more']
                }}</span>
                <span v-if="isShowmore[item]" @click="showMore(item, false)">{{
                  lbl['user-permissions-see-less']
                }}</span>
              </p>
              <p
                v-if="item === 'staff'"
                :class="['roleSubHeader', isShowmore[item] ? 'showmore' : '']"
              >
                {{ permissionstaff }}
                <span v-if="!isShowmore[item]" @click="showMore(item, true)">{{
                  lbl['user-permissions-see-more']
                }}</span>
                <span v-if="isShowmore[item]" @click="showMore(item, false)">{{
                  lbl['user-permissions-see-less']
                }}</span>
              </p> -->
            </div>
            <div class="roleBody">
              <div class="sortByContainer">
                <div class="sortItem">
                  {{ totalList(item) }}
                  {{ lbl['user-permissions-item-on-list'] }}
                </div>
                <div class="sortFilter">
                  <div class="btnSort">
                    {{ lbl['user-permissions-sortby'] }}
                    <img
                      :src="require('@/assets/images/arrow_drop_down.png')"
                      alt=""
                    />
                  </div>
                  <div class="sortDropdown">
                    <div @click="sortUserList(item, 'name')">
                      A-Z {{ lbl['user-permissions-name'] }}
                    </div>
                    <div @click="sortUserList(item, 'email')">
                      A-Z {{ lbl['user-permissions-email'] }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="userListContainer">
                <div
                  class="userList"
                  v-for="(user, index) in roleData[item].userList"
                  :key="index"
                >
                  <div
                    :class="['userStatus', user.Active ? 'active' : 'inactive']"
                  >
                    {{ user.Active ? 'active' : 'inactive' }}
                  </div>
                  <div class="detail">
                    <div class="userEmail">{{ user.Email }}</div>
                    <div class="userName">
                      {{ user.PosRole === 'Owner' ? '(Owner) ' : null
                      }}{{ user.FirstName }} {{ user.LastName }}
                    </div>
                  </div>
                  <div class="action">
                    <button
                      class="copy"
                      @click="toggleUserDetailModal(true, user, 'User Copied!')"
                    >
                      <img
                        :src="require('@/assets/images/Duplicate.png')"
                        alt=""
                      />
                    </button>
                    <button
                      class="edit"
                      @click="toggleUserModal(true, 'edit', user)"
                    >
                      {{ lbl['user-permissions-edit'] }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PermissionTypeModal
      :isPermissionTypeModal="isPermissionTypeModal"
      :title="permissionTypeTitle"
      :toggle="togglePermissionTypeModal"
    />
    <PermissionListModal
      :permissionList="permissionList"
      :isPermissionListModal="isPermissionListModal"
      :title="permissionListTitle"
      :toggle="togglePermissionModal"
    />
    <UserDetailModal
      :isUserDetailModal="isUserDetailModal"
      :toggle="toggleUserDetailModal"
      :UserDetail="UserDetail"
      :copyToClipBoard="copyToClipBoard"
      :brandId="brandId"
      :terminaId="terminaId"
      :title="userTitle"
    />
    <UserModal
      :isUserModal="isUserModal"
      :toggle="toggleUserModal"
      :brandId="brandId"
      :terminaId="terminaId"
      :toggleDetail="toggleUserDetailModal"
      :mode="mode"
      :form="editData"
    />
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsUserPermission from '@/core/UserPermission/service/BzbsUserPermission'
import Vue from 'vue'
import Header from '@/components/Layout/Header'
import CheckBox from './components/CheckBox.vue'
import PermissionTypeModal from '@/module/UserPermission/components/PermissionTypeModal.vue'
import PermissionListModal from './components/PermissionListModal'
import UserDetailModal from './components/UserDetailModal'
import UserModal from './components/UserModal'
import Breadcrumbs from '@/module/Settings/3rdParty/components/Breadcrumbs.vue'

export default {
  name: 'UserPermission',
  mixins: [Mixin],
  components: {
    Header,
    CheckBox,
    PermissionTypeModal,
    PermissionListModal,
    UserDetailModal,
    UserModal,
    Breadcrumbs,
  },
  data() {
    return {
      currenttitle: 'Settings',
      current: 'User permission',
      parent: null,
      allUser: 0,
      storeName: '',
      brandId: '',
      terminaId: '',
      isShowmore: {
        admin: false,
        operator: false,
        staff: false,
      },
      filterNameEmail: '',
      filter: {
        status: {
          value: null,
          input: ['active', 'inactive'],
          label: 'Status',
          type: 'checkbox',
        },
        branchName: {
          value: null,
          input: ['Branch Name'],
          label: 'Branch Name',
          type: 'text',
        },
        branchId: {
          value: null,
          input: ['Branch ID'],
          label: 'Branch ID',
          type: 'text',
        },
      },
      subFilter: 'status',
      showFilterMain: false,
      roleData: {
        admin: {
          header: 'Admin',
          key: 'admin',
          subHeader: this.permissionAdmin,
          userList: [],
        },
        operator: {
          header: 'Operator',
          key: 'operator',
          subHeader: this.permissionOperator,
          userList: [],
        },
        staff: {
          header: 'Staff',
          key: 'staff',
          subHeader: this.permissionStaff,
          userList: [],
        },
      },
      isPermissionTypeModal: false,
      isPermissionListModal: false,
      permissionTypeTitle: 'Permission type',
      permissionListTitle: 'Operator',
      permissionList: {},
      permissionListOperator: {},
      permissionListStaff: {
        dashboard: true,
        accountsetup: true,
        linesetup: true,
        manageuser: true,
        theme: true,
        importbatch: true,
        memberdetail: true,
        memberlevel: true,
        pointsetuptab: true,
        privatecycondition: true,
        download: true,
        loginmerchant: false,
        tagmerchant: false,
      },
      permissionListAdmin: {
        dashboard: false,
        accountsetup: false,
        linesetup: false,
        manageuser: false,
        theme: false,
        importbatch: false,
        memberdetail: false,
        memberlevel: false,
        pointsetuptab: false,
        privatecycondition: false,
        download: false,
      },
      isUserDetailModal: false,
      UserDetail: {},
      userTitle: '',
      isUserModal: false,
      mode: 'create',
      editData: {
        active: true,
        role: 'admin',
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        confirmPassword: '',
        email: '',
        contactNumber: '',
        branchIds: [],
      },
      bus: new Vue(),
      branchList: [],
      allFilter: {},
    }
  },
  watch: {
    lbl(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setLocale()
      }
    },
  },
  computed: {
    permissionadmin() {
      return Object.keys(this.permissionListAdmin)
        .filter(i => !this.permissionListAdmin[i])
        .map(i => this.lbl['user-permissions-' + i])
        .join(', ')
    },
    permissionoperator() {
      return Object.keys(this.permissionListOperator)
        .filter(i => !this.permissionListOperator[i])
        .map(i => this.lbl['user-permissions-' + i])
        .join(', ')
    },
    permissionstaff() {
      return Object.keys(this.permissionListStaff)
        .filter(i => !this.permissionListStaff[i])
        .map(i => this.lbl['user-permissions-' + i])
        .join(', ')
    },
  },
  created() {
    this.handleLoading(true)
    this.handleFooter(true)
    Promise.all([
      this.getUserListByFilter(),
      this.getOperatorPermission(),
      this.getBranchList(),
    ]).then(() => {
      this.handleLoading(false)
    })
  },
  methods: {
    setLocale() {
      this.currenttitle = this.lbl['main-menu-setting']
      this.current = this.lbl['user-permissions-title']
      this.parent = null
      this.permissionTypeTitle = this.lbl['permission-type-btn']
    },
    logdata() {
      console.log(this.allFilter)
    },
    toggle(data) {
      this[data] = !this[data]
    },
    togglePermissionTypeModal() {
      this.$router.push({ name: 'PermissionType' })
    },
    togglePermissionModal(type, role) {
      if (role)
        this.permissionListTitle = role.charAt(0).toUpperCase() + role.slice(1)
      if (role === 'operator') this.permissionList = this.permissionListOperator
      if (role === 'staff') this.permissionList = this.permissionListStaff
      if (role === 'admin') this.permissionList = this.permissionListAdmin
      this.isPermissionListModal = type
    },
    toggleUserDetailModal(type, data, title) {
      if (data) this.UserDetail = data
      this.userTitle = title
      if (title === 'User Created!') this.getUserListByFilter()
      this.isUserDetailModal = type
    },
    toggleUserModal(type, mode, data) {
      this.mode = mode
      if (mode === 'create' && type) {
        this.editData.branchIds = this.branchList.map(item => item.ID)
      }
      if (mode === 'edit' && type) {
        this.editData = {
          active: data.Active,
          role: data.CrmPlusRole,
          firstName: data.FirstName,
          lastName: data.LastName,
          userName: data.UserName,
          userNameApi: data.UserName,
          password: '',
          confirmPassword: '',
          email: data.Email,
          contactNumber: data.ContactNumber || '',
          branchIds: [...data.Branches],
          oldRole: data.Role,
          PartitionKey: data.PartitionKey || '',
          PosRole: data.PosRole || '',
        }
      }
      if (!type) {
        this.editData = {
          active: true,
          role: 'admin',
          firstName: '',
          lastName: '',
          userName: '',
          password: '',
          confirmPassword: '',
          email: '',
          contactNumber: '',
          branchIds: [],
        }
        this.getUserListByFilter()
      }
      this.isUserModal = type
    },
    onEnterInput() {
      this.getUserListByFilter()
    },
    showMore(type, data) {
      this.isShowmore[type] = data
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    sortUserList(type, order) {
      if (order === 'name')
        this.roleData[type].userList.sort((a, b) =>
          a.FirstName.localeCompare(b.FirstName),
        )
      if (order === 'email')
        this.roleData[type].userList.sort((a, b) =>
          a.Email.localeCompare(b.Email),
        )
    },
    copyToClipBoard(obj, type) {
      if (type) {
        navigator.clipboard.writeText(
          `UserName : ${obj.UserName}\r\n Password : ${obj.Password}\r\n Brand Id : ${this.brandId}\r\n Terminal Id : ${this.terminaId}\r\n Branches : ${obj.Branches}\r\n`,
        )
      } else {
        navigator.clipboard.writeText(
          `UserName : ${obj.UserName}\r\n Brand Id : ${this.brandId}\r\n Terminal Id : ${this.terminaId}\r\n Branches : ${obj.Branches}\r\n`,
        )
      }
    },
    totalList(type) {
      return this.roleData[type].userList.length
    },
    selectFilter(item) {
      if (
        (this.filter[this.subFilter].value === 'active' && item === 'active') ||
        (this.filter[this.subFilter].value === 'inactive' &&
          item === 'inactive')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.getUserListByFilter()
    },
    resetFilter() {
      this.filter.status.value = ''
      this.filter.branchName.value = ''
      this.filter.branchId.value = ''
      this.getUserListByFilter()
    },
    clearUserList() {
      Object.keys(this.roleData).forEach(item => {
        this.roleData[item].userList = []
      })
    },
    getBranchList() {
      BzbsProfile.getCRMPlusProfile()
        .then(res => {
          this.branchList = res.data.Stores
        })
        .catch(err => console.log(err))
    },
    getUserListByFilter() {
      this.showFilterMain = false
      this.allUser = 0
      this.clearUserList()
      let active = ''
      if (this.filter.status.value === 'active') active = true
      if (this.filter.status.value === 'inactive') active = false
      this.allFilter = Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})

      const Lfilter = {
        active,
        branchId: this.filter.branchId.value,
        branchName: this.filter.branchName.value,
      }
      this.handleLoading(true)
      Promise.all([
        BzbsUserPermission.getCRMPlusUserList(
          Lfilter,
          'staff',
          this.filterNameEmail,
        ),
        BzbsUserPermission.getCRMPlusUserList(
          Lfilter,
          'admin',
          this.filterNameEmail,
        ),
        BzbsUserPermission.getCRMPlusUserList(
          Lfilter,
          'operator',
          this.filterNameEmail,
        ),
        BzbsUserPermission.getCRMPlusStaffUserList(
          Lfilter,
          this.filterNameEmail,
        ),
      ])
        .then(res => {
          let flatData = res
            .map(item => item.data)
            .flat()
            .filter(i => !i.Deleted)
          this.allUser = flatData.length
          flatData.forEach(item => {
            if (item.PosRole === 'Owner') {
              this.roleData[item.CrmPlusRole].userList.unshift(item)
            } else {
              this.roleData[item.CrmPlusRole].userList.push(item)
            }
          })
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.handleLoading(false)
        })
    },
    getOperatorPermission() {
      BzbsUserPermission.getCRMPlusStoreInfo()
        .then(res => {
          this.storeName = res.data.BusinessName
          this.brandId = res.data.EwalletBrandId
          this.terminaId = Vue.bzbsConfig.businessRule.default.terminalId
          this.permissionListOperator = {
            dashboard: res.data.HideDashboard,
            accountsetup: res.data.HideAccountSetup,
            linesetup: res.data.HideLineSetup,
            manageuser: res.data.HideManageUser,
            theme: res.data.HideThemeSetting,
            importbatch: res.data.IsAllowImportBatch,
            memberdetail: res.data.HideMemberDetail,
            memberlevel: res.data.HasUserlevel,
            pointsetuptab: res.data.HidePointSetupTab,
            privatecycondition: res.data.HideMemberPrivacyMenu,
            download: res.data.HideDownloadMenu,
          }
        })
        .catch(err => console.log(err))
    },
  },
}
</script>

<style lang="scss" scoped>
.pb-permission {
  padding-bottom: 100px !important;
}
.mainContainer {
  border-radius: 5px;
  padding: 30px;
  @media only screen and (max-width: 460px) {
    padding: 6px;
  }
  border: solid 1px #fafafa;
  background-color: #fff;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}
.title {
  display: flex;
  align-items: center;
}
.textTitle {
  font-size: 34px;
  color: #000;
}
.chip {
  margin: 6px 0 0 16px;
  width: 49px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: solid 1px #ffb74d;
  background-color: #fff3e0;
}
.text-label {
  padding-right: 1px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;
  text-align: center;
  color: #f57c00;
}
.search {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
}
.searchContainer {
  flex-grow: 1;
  width: 444px;
  position: relative;
  @media only screen and (max-width: 570px) {
    width: auto;
  }
  img {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.searchInput {
  width: 100%;
  height: 56px;
  padding: 0 12px 0 16px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #e0e0e0;
  }
}
.searchBtn {
  flex-grow: 1;
  width: 195px;
  height: 56px;
  align-items: center;
  padding: 16px 24px;
  border-radius: 4px;
  background-color: #ff9800;
  color: #fff;
  font-size: 16px;
  border: none;
  img {
    margin: 0 8px 4px 0;
  }
}
.subTitle {
  margin-top: 16px;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #bdbdbd;
}
hr {
  margin: 24px -30px;
}
.filterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .btn-permission {
    width: 157px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: solid 1px #f9a601;
    background-color: #fff;
    color: #f9a601;
    font-size: 16px;
  }
}
.filter,
.filterItem {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  cursor: pointer;
  color: #616161;
  font-size: 16px;
  font-weight: 500;
  img {
    margin-left: 8px;
  }
}
.filterMain {
  position: relative;
  &.active {
    .filterDropdown {
      display: flex;
    }
  }
}
.filterDropdown {
  z-index: 3;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  top: 52px;
  position: absolute;
  background-color: #fff;
  display: none;
  padding: 0 0 64px 0;
  font-size: 16px;
  .filterLeft {
    width: 250px;
    border-right: 1px solid #e0e0e0;
    @media only screen and (max-width: 460px) {
      width: auto;
    }
  }
  .filterRight {
    width: 250px;
    @media only screen and (max-width: 460px) {
      width: auto;
    }
    &:hover {
      display: block;
    }
    .filterList {
      justify-content: flex-start;
      input {
        cursor: pointer;
      }
      &.input {
        input {
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          padding: 5px;
          cursor: auto;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  .filterList {
    height: 42px;
    width: 100%;
    padding: 0 24px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
      background-color: #fff3e0;
    }
    &:hover {
      background-color: #fff3e0;
    }
  }
}
.btnFilter {
  width: 100%;
  position: absolute;
  bottom: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: blue;
  div {
    cursor: pointer;
  }
  button {
    align-items: center;
    padding: 6px 18px;
    border-radius: 4px;
    background-color: #ff9800;
    color: #fff;
    border: none;
  }
}
.roleContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px 0 0 0;
  .roleItem {
    width: 410px;
    @media only screen and (max-width: 550px) {
      width: 100%;
    }
    flex-grow: 1;
    .roleHeader {
      // min-height: 120px;
      border: 1px solid #e0e0e0;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      padding: 16px;
      .headerTab {
        display: flex;
        justify-content: space-between;
        button {
          width: 56px;
          height: 35px;
          border: 1px solid #e0e0e0;
          background-color: #fff;
          border-radius: 5px;
        }
      }
      .textTitle {
        font-size: 20px;
      }
      .chip {
        margin: 0 0 0 8px;
      }
      .roleSubHeader {
        margin-top: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-right: 62px;
        position: relative;
        margin-bottom: 0;
        min-height: 44px;
        &.showmore {
          -webkit-line-clamp: unset;
        }
        span {
          position: absolute;
          bottom: 0;
          right: 0;
          color: #2196f3;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .roleBody {
      height: 449px;
      border: 1px solid #e0e0e0;
      border-radius: 0 0 5px 5px;
      background-color: #fafafa;
      .sortByContainer {
        height: 56px;
        padding: 16px;
        color: #bdbdbd;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        .sortFilter {
          &:hover {
            .sortDropdown {
              display: block;
              color: black;
            }
          }
          cursor: pointer;
          position: relative;
          .sortDropdown {
            display: none;
            border-radius: 5px;
            border: 1px solid #e0e0e0;
            width: 124px;
            position: absolute;
            right: 0;
            background-color: white;
            div:first-child {
              border-bottom: 1px solid #e0e0e0;
            }
            div {
              text-align: center;
              padding: 6px;
              cursor: pointer;
              &:hover {
                background-color: #fff3e0;
              }
            }
          }
        }
      }
      .userListContainer {
        overflow-y: scroll;
        height: calc(100% - 56px);
        /* width */
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .userList {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          background-color: white;
          gap: 22px;
          padding: 16px;
          border-bottom: 1px solid #e0e0e0;
          @media only screen and (max-width: 460px) {
            padding: 16px 4px;
            flex-wrap: wrap;
            gap: 22px;
            justify-content: center;
          }
          .userStatus {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 32px;
            font-weight: 500;
            border-radius: 24px;
            &.active {
              color: #4caf50;
              background-color: #4caf5033;
            }
            &.inactive {
              color: #ff5252;
              background-color: #ff525233;
            }
          }
          .detail {
            text-align: left;
            margin-right: auto;
            margin-left: auto;
            @media only screen and (max-width: 460px) {
              width: 100%;
              text-align: center;
            }
            .userEmail {
              color: #bdbdbd;
              white-space: nowrap;
              width: 145px;
              margin: 0 auto;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .userName {
              font-size: 16px;
              font-weight: 600;
              margin: 5px auto 0 auto;
              color: #616161;
              white-space: nowrap;
              width: 145px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .action {
            margin-left: auto;
            display: flex;
            gap: 8px;
            @media only screen and (max-width: 460px) {
              margin-left: 0;
            }
            > * {
              width: 56px;
              height: 35px;
              color: #2196f3;
              font-size: 16px;
              font-weight: 500;
              text-decoration: underline;
              border-radius: 5px;
              border: 1px solid #e0e0e0;
              background-color: white;
            }
            .copy {
              border-color: #1e88e51a;
            }
            .edit {
              border-color: #bdbdbd;
            }
          }
        }
      }
    }
  }
}
</style>
