<template>
  <div>
    <div
      :class="['backDrop', isUserModal ? 'isShow' : '']"
      @click="onCloseModal()"
    ></div>
    <div ref="userModal" :class="['userModal', isUserModal ? 'isShow' : '']">
      <div class="header">
        <div class="title">
          {{
            mode === 'create'
              ? lbl['user-permissions-add-newuser']
              : lbl['user-permissions-edit-user']
          }}
        </div>
        <div class="headerDetail">{{ detail }}</div>
      </div>
      <div class="content">
        <div class="tabContainer">
          <div class="assignTo">
            {{ lbl['user-permissions-role'] }}
            <div class="tooltipContainer">
              <img
                :src="require('@/assets/images/accountsetup/info.svg')"
                alt=""
              />
              <div class="textTooltip">
                {{ lbl['user-permissions-role-tooltip'] }}
              </div>
            </div>
          </div>
          <div class="userStatus">
            {{ lbl['user-permissions-user-status'] }}
            <div class="tooltipContainer">
              <img
                :src="require('@/assets/images/accountsetup/info.svg')"
                alt=""
              />
              <div class="textTooltip">
                {{ lbl['user-permissions-user-status-tooltip'] }}
              </div>
            </div>
            <label class="switch">
              <input
                type="checkbox"
                v-model="form.active"
                :disabled="form.PosRole === 'Owner'"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="radioGroup">
          <Radio
            v-for="(item, index) in radioInput"
            :key="item.label + index"
            :onChangeFunction="onChangeRadio"
            :thisvalue="item.value"
            :label="item.label"
            group="Role"
            :thisId="item.value"
            :checked="form.role"
            :disabled="
              mode === 'edit' &&
              (!form.active ||
                form.oldRole === 'staff' ||
                form.PosRole === 'Owner' ||
                roleVal === 'staff')
            "
          />
        </div>
        <div class="nameLastName">
          <div class="firstName width355">
            <div class="textLabel">{{ lbl['user-permissions-firstname'] }}</div>
            <input
              type="text"
              :placeholder="lbl['user-permissions-firstname']"
              v-model="form.firstName"
              :disabled="mode === 'edit' && !form.active"
            />
          </div>
          <div class="lastName width355">
            <div class="textLabel">{{ lbl['user-permissions-lastname'] }}</div>
            <input
              type="text"
              :placeholder="lbl['user-permissions-lastname']"
              v-model="form.lastName"
              :disabled="mode === 'edit' && !form.active"
            />
          </div>
        </div>
        <div class="userName">
          <div class="userInput width355">
            <div class="textLabel">{{ lbl['user-permissions-username'] }}</div>
            <div class="usernameContainer">
              <input
                type="text"
                :placeholder="lbl['user-permissions-username']"
                v-model="form.userName"
                maxlength="20"
                :disabled="mode === 'edit'"
              />
              <img
                v-if="mode === 'edit'"
                @click="copyToClipBoard(form.userName)"
                :src="require('@/assets/images/dup_gray.png')"
                alt=""
              />
              <img
                v-if="usernameDanger && mode !== 'edit'"
                :src="require('@/assets/images/cross_red.png')"
                alt=""
              />
            </div>
            <p class="danger">{{ usernameDanger }}</p>
          </div>
          <div class="detail">
            <p :class="[vMinMaxLength('userName') ? 'pass' : '']">
              {{ lbl['user-permissions-username-min-max-charactor-validate'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vMinMaxLength('userName') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vSymbolsLowerCase('userName') ? 'pass' : '']">
              {{ lbl['user-permissions-username-eng-lowercase-validate'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vSymbolsLowerCase('userName') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
          </div>
        </div>
        <div class="password">
          <div class="passwordInput width355">
            <div class="textLabel">{{ lbl['user-permissions-password'] }}</div>
            <div class="passwordEye">
              <input
                id="paste-no-event"
                :type="isShowPassword ? 'text' : 'password'"
                :placeholder="lbl['user-permissions-password']"
                v-model="form.password"
                maxlength="20"
                @keypress="isNumber($event)"
                :disabled="mode === 'edit' && !isEditPassword"
              />
              <img
                @click="toggleShowPassword()"
                :class="[isShowPassword ? 'showPassword' : '']"
                :src="require('@/assets/images/eye.png')"
                alt=""
              />
            </div>
            <input
              id="paste-no-event-cfpassword"
              :type="isShowPassword ? 'text' : 'password'"
              class="cfPassword"
              :placeholder="lbl['user-permissions-cfpassword']"
              v-model="form.confirmPassword"
              maxlength="20"
              @keypress="isNumber($event)"
              :disabled="mode === 'edit' && !isEditPassword"
            />
            <p v-if="!passwordMatchState" class="danger">
              {{ lbl['user-permissions-passwordnotmatch'] }}
            </p>
          </div>
          <div class="actionBtn" v-if="mode === 'edit' && !isEditPassword">
            <button
              @click="changePassword(true)"
              class="cancelBtn changePassword"
              :disabled="mode === 'edit' && !form.active"
            >
              {{ lbl['user-permissions-changepassword'] }}
            </button>
          </div>
          <div
            class="detail"
            v-if="
              form.role === 'staff' && (isEditPassword || mode === 'create')
            "
          >
            <p>
              {{ lbl['user-permissions-password-validate-1'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vNoSpaceMinMaxLength('password')
                      ? 'check_circle'
                      : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vSymbolsTh('password') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-password-validate-5'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vSymbolsTh('password') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
          </div>

          <div
            class="detail"
            v-if="
              form.role !== 'staff' && (isEditPassword || mode === 'create')
            "
          >
            <p>
              {{ lbl['user-permissions-password-min-max-charactor-validate'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vNoSpaceMinMaxLength('password')
                      ? 'check_circle'
                      : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vCharCase('password') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-password-validate-3'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vCharCase('password') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vContainNumber('password') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-password-validate-4'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vContainNumber('password') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vSymbols('password') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-password-validate-5'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vSymbols('password') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vSymbolsTh('password') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-password-validate-5'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vSymbolsTh('password') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vSymbolsEn('password') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-password-validate-5'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vSymbolsEn('password') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
            <p :class="[vSymbolsEnLow('password') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-password-validate-5'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vSymbolsEnLow('password') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>
          </div>
        </div>
        <div class="email">
          <div class="emailInput width355">
            <div class="textLabel">{{ lbl['user-permissions-email'] }}</div>
            <input
              type="text"
              :placeholder="lbl['user-permissions-email']"
              v-model="form.email"
              :disabled="mode === 'edit'"
            />
            <p v-if="!emailState && emailState !== null" class="danger">
              {{ lbl['user-permissions-emailvalidate'] }}
            </p>
            <p :class="[vSymbolsTh('email') ? 'pass' : '', 'd-none']">
              {{ lbl['user-permissions-emailvalidate'] }}
              <img
                :src="
                  require(`@/assets/images/${
                    vSymbolsTh('email') ? 'check_circle' : 'cross_red'
                  }.png`)
                "
                alt=""
              />
            </p>

            <p class="danger">{{ emaildup }}</p>
          </div>
          <div class="phoneInput width355">
            <div class="textLabel">{{ lbl['user-permissions-phone'] }}</div>
            <input
              type="text"
              :placeholder="lbl['user-permissions-phone']"
              v-model="form.contactNumber"
              :maxlength="phoneMaxlength"
              @keypress="isNumber($event)"
              @input="filterNonNumeric"
              :disabled="mode === 'edit' && !form.active"
            />
            <p v-if="!phoneState && phoneState !== null" class="danger">
              {{ lbl['user-permissions-phonevalidate'] }}
            </p>
          </div>
        </div>
        <div class="textdetail">
          <p>
            {{ lbl['user-permissions-brand-id'] }}: {{ brandId }}
            {{ lbl['user-permissions-terminal'] }}: {{ terminaId }}
          </p>
        </div>
        <div class="Branch">
          <div class="textLabel">{{ lbl['user-permissions-branch'] }}</div>
          <div class="checkBoxContainer">
            <div class="d-flex">
              <div class="thisCheckBox" @click="onClickCheckBox('allBranch')">
                <CheckBox
                  label="All Branch"
                  valuee="allBranch"
                  :normalCheck="
                    branchList.every((d, i) => d.ID === form.branchIds[i])
                  "
                  :disabled="mode === 'edit' && !form.active"
                />
              </div>
            </div>

            <div class="allBranch">
              <div
                class="thisCheckBox"
                v-for="(item, index) in branchList"
                @click="onClickCheckBox(item.ID)"
                :key="item.Name + index"
              >
                <CheckBox
                  :label="item.Name + ' (' + item.ID + ')'"
                  :valuee="item.ID.toString()"
                  :normalCheck="form.branchIds.includes(item.ID)"
                  :disabled="mode === 'edit' && !form.active"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actionBtn bottomAction">
        <div class="deleteBtn">
          <div
            v-if="mode === 'edit' && form.PosRole != 'Owner'"
            @click="toggleDeleteModal(true)"
          >
            {{ lbl['user-permissions-delete'] }}
          </div>
        </div>
        <div
          :class="[
            'd-flex justify-content-end',
            mode === 'edit' ? '' : 'w-100',
          ]"
        >
          <button class="cancelBtn" @click="onCloseModal()">
            {{ lbl['user-permissions-cancel'] }}
          </button>
          <button class="saveBtn" :disabled="!formState" @click="saveAction()">
            {{
              mode === 'create'
                ? lbl['user-permissions-save']
                : lbl['user-permissions-update']
            }}
          </button>
        </div>
      </div>
    </div>
    <DeleteModal
      :toggle="toggleDeleteModal"
      :isDeleteModal="isDeleteModal"
      :cfDelete="postDeleteUser"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import Radio from './Radio'
import CheckBox from './CheckBox'
import DeleteModal from './DeleteModal'
import BzbsUserPermission from '@/core/UserPermission/service/BzbsUserPermission'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Vue from 'vue'
export default {
  mixins: [Mixin],
  components: {
    Radio,
    CheckBox,
    DeleteModal,
  },
  data() {
    return {
      form1: {
        active: false,
        role: 'admin',
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        confirmPassword: '',
        email: '',
        contactNumber: '',
        branchIds: [],
      },
      radioInput: [
        { label: 'Admin', value: 'admin', key: 'permission-type-admin-role' },
        {
          label: 'Operator',
          value: 'operator',
          key: 'permission-type-operator-role',
        },
        { label: 'Staff', value: 'staff', key: 'permission-type-staff-role' },
      ],
      branchList: [],
      usernameDanger: '',
      emaildup: '',
      isShowPassword: false,
      isEditPassword: false,
      isDeleteModal: false,
      phoneMaxlength: 10,
      roleVal: 'admin'
    }
  },
  props: {
    userData: {
      type: Object,
    },
    isUserModal: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
    },
    toggleDetail: {
      type: Function,
    },
    title: {
      type: String,
    },
    detail: {
      type: String,
    },
    brandId: {
      type: String,
    },
    terminaId: {
      type: String,
    },
    mode: {
      type: String,
    },
    form: {
      type: Object,
    },
    bus: {},
  },
  computed: {
    userNameState() {
      return (
        this.vSymbolsTh('userName') &&
        this.vSymbolsLowerCase('userName') &&
        this.vMinMaxLength('userName')
      )
    },
    passwordMatchState() {
      return this.form.password === this.form.confirmPassword
    },
    passwordState() {
      if (this.mode === 'edit' && !this.isEditPassword) return true
      if (this.form.password === '') return null
      if (this.form.role === 'staff')
        return (
          this.vMinLength('password') &&
          this.vSymbolsTh('password') &&
          this.vSymbolsEn('password') &&
          this.vSymbolsEnLow('password')
        )
      if (this.form.password !== this.form.confirmPassword) {
        return false
      }
      return this.vMinLength('password') && this.vSymbolsTh('password')
    },
    emailState() {
      if (this.form.email === '') return null
      var rex =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return rex.test(this.form.email) && this.vSymbolsTh('email')
    },
    phoneState() {
      if (this.form.contactNumber === '') return null
      return (
        this.form.contactNumber.length ===
        Vue.bzbsConfig.businessRule.max.phoneLength
      )
    },
    branchState() {
      if (this.branchList.length < 1) return true
      return this.form.branchIds.length > 0
    },
    formState() {
      return (
        this.form.firstName &&
        this.form.lastName &&
        this.passwordState &&
        this.phoneState &&
        this.emailState &&
        this.branchState &&
        this.userNameState
      )
    },
  },
  mounted() {
    this.getBranchList()
    this.phoneMaxlength = Vue.bzbsConfig.businessRule.max.phoneLength
  },
  watch: {
    form(newVal, oldVal) {
      console.log('form : ', newVal, oldVal)
      this.roleVal = newVal.role
    },
  },
  created() {
    this.setLocale()
  },
  methods: {
    setLocale() {
      _.forEach(this.radioInput, item => {
        item.label = this.lbl[item.key]
      })
    },
    vMinLength(type) {
      return this.form[type].length >= 6
    },
    vMaxLength(type) {
      return this.form[type].length <= 20 && this.form[type].length > 0
    },
    vMinMaxLength(type) {
      return this.form[type].length <= 20 && this.form[type].length >= 6
    },
    vNoSpaceMinMaxLength(type) {
      return (
        this.form[type].length <= 20 &&
        this.form[type].length >= 6 &&
        !/[\s]/.test(this.form[type])
      )
    },
    vContainNumber(type) {
      return this.form[type].split('').some(i => !isNaN(i))
    },
    vSymbols(type) {
      let format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
      return format.test(this.form[type])
    },
    vSymbolsTh(type) {
      let format = /([ก-๙])/
      console.log('vSymbols Th: ', type, !format.test(this.form[type]))
      return !format.test(this.form[type])
    },
    vSymbolsEn(type) {
      let format = /([A-Z])/
      return !format.test(this.form[type])
    },
    vSymbolsEnLow(type) {
      let format = /([a-z])/
      return !format.test(this.form[type])
    },
    vSymbolsLowerCase(type) {
      let format = /(^[a-z0-9]+$)/
      return format.test(this.form[type])
    },
    vCharCase(type) {
      let upper = this.form[type].split('').some(i => {
        if (!isNaN(i)) return false
        return (
          i === i.toUpperCase() &&
          i.charCodeAt(0) >= 65 &&
          i.charCodeAt(0) <= 90
        )
      })

      let lower = this.form[type].split('').some(i => {
        if (!isNaN(i)) return false
        return (
          i === i.toLowerCase() &&
          i.charCodeAt(0) >= 97 &&
          i.charCodeAt(0) <= 122
        )
      })
      return upper && lower
    },
    toggleShowPassword() {
      this.isShowPassword = !this.isShowPassword
    },
    toggleDeleteModal(type) {
      this.isDeleteModal = type
    },
    copyToClipBoard(data) {
      navigator.clipboard.writeText(data)
    },
    changePassword(data) {
      this.isEditPassword = data
    },
    filterNonNumeric() {
      // Replace non-numeric characters with an empty string
      this.form.contactNumber = this.form.contactNumber.replace(/[^0-9]/g, '')
    },
    isNumber(evt) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      if (this.form.role === 'staff') {
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault()
        } else {
          return true
        }
      } else {
        return true
      }
    },
    onChangeRadio(value) {
      this.form.role = value.target.value
      if (this.form.role === 'staff') {
        this.form.password = ''
        this.form.confirmPassword = ''
        const pasteBox = document.getElementById('paste-no-event')
        pasteBox.onpaste = e => {
          e.preventDefault()
          return false
        }
        const pasteBoxCF = document.getElementById('paste-no-event-cfpassword')
        pasteBoxCF.onpaste = e => {
          e.preventDefault()
          return false
        }
      }
    },
    onClickCheckBox(value) {
      if (this.mode === 'edit' && !this.form.active) return
      if (value === 'allBranch') {
        if (this.branchList.every((d, i) => d.ID === this.form.branchIds[i])) {
          this.form.branchIds = []
        } else {
          this.form.branchIds = this.branchList.map(item => item.ID)
        }
      } else {
        if (this.form.branchIds.some(i => i === value)) {
          return (this.form.branchIds = this.form.branchIds.filter(
            i => i !== value,
          ))
        } else {
          this.form.branchIds.push(value)
          if (this.branchList.length === this.form.branchIds.length) {
            this.form.branchIds = this.branchList.map(item => item.ID)
          }
        }
      }
    },
    getBranchList() {
      BzbsProfile.getCRMPlusProfile()
        .then(res => {
          this.branchList = res.data.Stores
          this.form.branchIds = this.branchList.map(item => item.ID)
        })
        .catch(err => console.log(err))
    },
    saveAction() {
      if (this.mode === 'create') {
        this.postCreateUser()
      } else {
        this.postUpdateUser()
      }
    },
    scrollToTop() {
      this.$refs.userModal.scrollTop = 0
    },
    onCloseModal() {
      this.usernameDanger = ''
      this.emaildup = ''
      this.changePassword(false)
      this.toggle(false)
      this.scrollToTop()
    },
    postCreateUser() {
      this.handleLoading(true)
      BzbsUserPermission.postCRMPlusCreateUser(this.form)
        .then(res => {
          console.log(res)
          let user = {
            UserName: this.form.userName,
            Password: this.form.password,
            BrandId: this.brandId,
            TerminalId: this.terminaId,
            Branches: this.form.branchIds,
          }
          this.onCloseModal()
          setTimeout(() => {
            this.toggleDetail(true, user, 'User Created!')
          }, 500)
        })
        .catch(err => {
          console.log(err.message)
          if (
            err.response.data.error.message ==
            'This username has already been taken.'
          ) {
            this.usernameDanger = this.lbl['user-permissions-usernametaken']
          }
          if (err.response.data.error.message == 'DuplicateEmail') {
            this.emaildup = this.lbl['user-permissions-dupemail']
          }
        })
        .finally(() => {
          this.handleLoading(false)
        })
    },
    postUpdateUser() {
      this.handleLoading(true)
      BzbsUserPermission.postCRMPlusUpdateUser(this.form)
        .then(() => {
          this.onCloseModal()
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
    postDeleteUser() {
      this.handleLoading(true)
      BzbsUserPermission.postCRMPlusDeleteUser(this.form)
        .then(() => {
          this.toggleDeleteModal(false)
          this.onCloseModal()
        })
        .catch(err => console.log(err))
        .finally(() => this.handleLoading(false))
    },
  },
}
</script>

<style lang="scss" scoped>
.userModal {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 90%;
  max-width: 800px;
  min-height: 256px;
  max-height: 92vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  z-index: 728;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  .header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    border-bottom: solid 1px #e0e0e0;
    .title {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .content {
    padding: 30px;
    @media only screen and (max-width: 460px) {
      padding: 8px;
    }
    .tabContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      font-size: 16px;
      font-weight: 600;
      .tooltipContainer {
        position: relative;
        .textTooltip {
          visibility: hidden;
          opacity: 0;
          font-size: 12px;
          padding: 8px;
          position: absolute;
          top: -34px;
          background-color: white;
          z-index: 2;
          width: 380px;
          text-align: center;
          border: solid 1px #e0e0e0;
          border-radius: 5px;
          transition: all 0.3s ease-in;
        }
        &:hover {
          .textTooltip {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .assignTo {
        display: flex;
        align-items: center;
      }
      .userStatus {
        display: flex;
        align-items: center;
        .tooltipContainer {
          .textTooltip {
            right: -14px;
          }
        }
        label {
          margin: 0 0 0 16px;
        }
      }
      img {
        margin-left: 8px;
      }
    }
    .radioGroup {
      margin: 18px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-right: 52px;
      }
    }
    input[type='text'],
    input[type='password'] {
      width: 100%;
      height: 40px;
      border: solid 1px #e0e0e0;
      border-radius: 5px;
      padding: 8px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #e0e0e0;
      }
      &.dangerInput {
        border: solid 1px #ff5252;
      }
    }
    .textLabel {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 8px 0;
    }
    .cfPassword {
      margin: 20px 0 0 0;
    }
    .passwordInput {
      display: flex;
      flex-direction: column;
      .passwordEye {
        position: relative;
        img {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          &.showPassword {
            filter: invert(0%) sepia(90%) saturate(7500%) hue-rotate(309deg)
              brightness(88%) contrast(112%);
          }
        }
      }
    }
    .width355 {
      width: 355px;
    }
    .nameLastName,
    .userName,
    .password,
    .email {
      margin: 20px 0 0 0;
      display: flex;
      //   justify-content: space-between;
      flex-wrap: wrap;
      gap: 20px;
    }
    .usernameContainer {
      position: relative;
      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        cursor: pointer;
      }
    }
    .detail {
      font-size: 12px;
      margin: 32px 0 0 0;
      width: 355px;
      p.pass {
        color: #ccc;
      }
    }
    .textdetail,
    .Branch {
      margin: 20px 0 0 0;
      .allBranch {
        display: grid;
        grid-template-columns: auto auto auto;
        padding: 10px 0;
      }
      .thisCheckBox {
        cursor: pointer;
        font-size: 30px;
        text-align: left;
      }
      .checkBoxContainer {
        margin: 20px 0 0 0;
      }
    }
  }
  .actionBtn {
    font-size: 16px;
    font-weight: 500;
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    &.bottomAction {
      padding: 21px 30px;
      border-top: solid 1px #e0e0e0;
    }
    .deleteBtn {
      border: none;
      color: #ff5252;
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
    .cancelBtn {
      width: 200px;
      height: 48px;
      padding: 10px 20px;
      border-radius: 4px;
      margin-right: 24px;
      border: solid 1px #ff9800;
      color: #ff9800;
      background-color: white;
      &.changePassword {
        height: 40px;
        padding: 0;
      }
      &:disabled {
        background-color: white;
        border-color: #e0e0e0;
        color: #bdbdbd;
      }
      @media only screen and (max-width: 575px) {
        width: 140px;
      }
    }
    .saveBtn {
      width: 200px;
      height: 48px;
      padding: 10px 20px;
      border-radius: 4px;
      border: solid 1px #ff9800;
      color: white;
      background-color: #ff9800;
      &:disabled {
        background-color: #e0e0e0;
        border-color: #e0e0e0;
        color: #bdbdbd;
      }
      @media only screen and (max-width: 575px) {
        width: 140px;
      }
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgba(76, 175, 80, 0.2);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  background-color: #4caf50;
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.backDrop {
  z-index: 4;

  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.danger {
  color: #ff5252;
  font-size: 12px;
}
@media only screen and (max-width: 902px) {
  .width355 {
    flex-grow: 1;
  }
}
</style>
