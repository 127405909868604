<template>
  <div>
    <div
      :class="['backDrop', isPermissionListModal ? 'isShow' : '']"
      @click="toggle(false)"
    ></div>
    <div
      :class="['permissionListModal', isPermissionListModal ? 'isShow' : '']"
    >
      <div class="modalHeader">
        <div class="header">{{ title }}</div>
        <img
          @click="toggle(false)"
          :src="require('@/assets/images/close.png')"
          alt=""
        />
      </div>
      <div class="accessTo">Access To</div>
      <div
        class="permissionList"
        v-for="item in Object.keys(permissionList)"
        :key="'acc' + item"
      >
        <img
          :src="
            require(`@/assets/images/${
              !permissionList[item] ? 'check_circle' : 'cross_red'
            }.png`)
          "
          alt=""
        />
        <div class="permissionText">{{ lbl['user-permissions-' + item] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
export default {
  mixins: [Mixin],
  props: {
    permissionList: {
      type: Object,
    },
    isPermissionListModal: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
    },
    title: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.permissionListModal {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 500px;
  max-width: 90vw;
  min-height: 256px;
  overflow-y: scroll;
  max-height: 80vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  z-index: 728;
  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px;
    border-bottom: solid 2px #e0e0e0;
    .header {
      font-size: 20px;
      font-weight: 500;
    }
    img {
      cursor: pointer;
    }
  }
  font-size: 14px;
  font-weight: 500;
  .accessTo {
    height: 40px;
    padding: 10px;
    background-color: #f5f5f5;
  }
  .permissionList {
    height: 44px;
    padding: 12px;
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
}
.backDrop {
  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
