<template>
  <div>
    <div
      :class="['backDrop', isPermissionTypeModal ? 'isShow' : '']"
      @click="toggle(false)"
    ></div>
    <div
      :class="['permissionTypeModal', isPermissionTypeModal ? 'isShow' : '']"
    >
      <div class="modalHeader">
        <div class="header">
          {{ title }}
        </div>
        <img
          @click="toggle(false)"
          :src="require('@/assets/icon-close-permission.svg')"
          alt=""
        />
      </div>
      <!--  -->
      <div class="permission-type-modal">
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="{
            pageSize: 50,
            hideOnSinglePage: true,
          }"
        >
          <span slot="admin" slot-scope="text, key">
            <img
              v-if="
                key.key != 'permission-type-member' &&
                key.key != 'permission-type-setting' &&
                key.key != 'permission-type-smart-assistant'
              "
              src="@/assets/images/checktrue.svg"
              alt=""
            />
            <span
              v-if="
                key.key == 'permission-type-member' &&
                key.key == 'permission-type-setting' &&
                key.key == 'permission-type-smart-assistant'
              "
            ></span>
          </span>
          <!--  -->
          <span slot="operator" slot-scope="text, key">
            <span
              v-if="
                key.key == 'permission-type-member-levels' ||
                key.key == 'permission-type-import-member'
              "
            >
              <img src="@/assets/images/checkfalse.svg" alt="" />
            </span>
            <span
              v-else-if="
                key.key == 'permission-type-member' ||
                key.key == 'permission-type-smart-assistant' ||
                key.key == 'permission-type-setting'
              "
            ></span>
            <span v-else
              ><img src="@/assets/images/checktrue.svg" alt=""
            /></span>
          </span>
          <!--  -->
          <span slot="staff" slot-scope="text, key">
            <span
              v-if="
                key.key == 'permission-type-login-merchant' ||
                key.key == 'permission-type-tag'
              "
            >
              <img src="@/assets/images/checktrue.svg" alt="" />
            </span>
            <span
              v-else-if="
                key.key == 'permission-type-member' ||
                key.key == 'permission-type-smart-assistant' ||
                key.key == 'permission-type-setting'
              "
            ></span>
            <span v-else
              ><img src="@/assets/images/checkfalse.svg" alt=""
            /></span>
          </span>
        </a-table>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
const columns = [
  {
    dataIndex: 'menu',
    key: 'menu',
    title: 'Menu',
    align: 'left',
  },
  {
    title: 'Admin',
    dataIndex: 'admin',
    key: 'admin',
    align: 'center',
    width: 90,
    scopedSlots: { customRender: 'admin' },
  },
  {
    title: 'Operator',
    key: 'operator',
    dataIndex: 'operator',
    align: 'center',
    width: 90,
    scopedSlots: { customRender: 'operator' },
  },
  {
    title: 'Staff',
    dataIndex: 'staff',
    key: 'staff',
    align: 'center',
    width: 90,
    scopedSlots: { customRender: 'staff' },
  },
]

const data = [
  {
    key: 'permission-type-dashboard',
    menu: 'Dashboard',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-point-rewards',
    menu: 'Points & Rewards',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-point-earn',
    menu: 'Point Earning Setup',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-reward-setup',
    menu: 'Rewards Setup',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-friend-get-friends',
    menu: 'Friend Get Friends',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-member',
    menu: 'Member',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-member-details',
    menu: 'Member Details',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-member-levels',
    menu: 'Member Levels',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-import-member',
    menu: 'Import Member',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-smart-assistant',
    menu: 'Smart Assistant',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-targeted-message',
    menu: 'Targeted Message',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-noti',
    menu: 'Notification',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-setting',
    menu: 'Setting',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-account-setting',
    menu: 'Account Settings',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-richmenu-setup',
    menu: 'Rich Menu Setup',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-user-per',
    menu: 'User Permissions',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-theme',
    menu: 'Theme',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-privacy',
    menu: 'Privacy Conditions',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-extensions',
    menu: 'Integration',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-profile',
    menu: 'My Profile',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-manual',
    menu: 'User Manual',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-login-merchant',
    menu: 'Login Merchant',
    staff: '',
    admin: '',
    operator: '',
  },
  {
    key: 'permission-type-tag',
    menu: 'Tag Management',
    staff: '',
    admin: '',
    operator: '',
  },
]
import Mixin from '@/mixin/Mixin'
import _ from 'lodash'

export default {
  mixins: [Mixin],
  data() {
    return {
      data,
      columns,
    }
  },
  props: {
    isPermissionTypeModal: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
    },
    title: {
      type: String,
    },
  },
  watch: {
    lbl(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setLocale()
      }
    },
  },
  created() {},
  methods: {
    setLocale() {
      this.columns[0].title = this.lbl['permission-type-menu']
      this.columns[1].title = this.lbl['permission-type-admin']
      this.columns[2].title = this.lbl['permission-type-operator']
      this.columns[3].title = this.lbl['permission-type-staff']
      //
      _.forEach(this.data, item => {
        if (item.key == 'permission-type-dashboard') {
          item.menu = this.lbl['permission-type-dashboard']
        }
        if (item.key == 'permission-type-point-rewards') {
          item.menu = this.lbl['permission-type-point-rewards']
        }
        if (item.key == 'permission-type-point-earn') {
          item.menu = '•    ' + this.lbl['permission-type-point-earn']
        }
        if (item.key == 'permission-type-reward-setup') {
          item.menu = '•    ' + this.lbl['permission-type-reward-setup']
        }
        if (item.key == 'permission-type-friend-get-friends') {
          item.menu = '•    ' + this.lbl['permission-type-friend-get-friends']
        }
        if (item.key == 'permission-type-member') {
          item.menu = this.lbl['permission-type-member']
        }
        if (item.key == 'permission-type-member-details') {
          item.menu = '•    ' + this.lbl['permission-type-member-details']
        }
        if (item.key == 'permission-type-member-levels') {
          item.menu = '•    ' + this.lbl['permission-type-member-levels']
        }
        if (item.key == 'permission-type-import-member') {
          item.menu = '•    ' + this.lbl['permission-type-import-member']
        }
        if (item.key == 'permission-type-smart-assistant') {
          item.menu = this.lbl['permission-type-smart-assistant']
        }
        if (item.key == 'permission-type-targeted-message') {
          item.menu = '•    ' + this.lbl['permission-type-targeted-message']
        }
        if (item.key == 'permission-type-noti') {
          item.menu = '•    ' + this.lbl['permission-type-noti']
        }
        if (item.key == 'permission-type-setting') {
          item.menu = this.lbl['permission-type-setting']
        }
        if (item.key == 'permission-type-account-setting') {
          item.menu = '•    ' + this.lbl['permission-type-account-setting']
        }
        if (item.key == 'permission-type-richmenu-setup') {
          item.menu = '•    ' + this.lbl['permission-type-richmenu-setup']
        }
        if (item.key == 'permission-type-user-per') {
          item.menu = '•    ' + this.lbl['permission-type-user-per']
        }
        if (item.key == 'permission-type-theme') {
          item.menu = '•    ' + this.lbl['permission-type-theme']
        }
        if (item.key == 'permission-type-privacy') {
          item.menu = '•    ' + this.lbl['permission-type-privacy']
        }
        if (item.key == 'permission-type-extensions') {
          item.menu = '•    ' + this.lbl['permission-type-extensions']
        }
        if (item.key == 'permission-type-profile') {
          item.menu = this.lbl['permission-type-profile']
        }
        if (item.key == 'permission-type-manual') {
          item.menu = '•    ' + this.lbl['permission-type-manual']
        }
        if (item.key == 'permission-type-login-merchant') {
          item.menu = this.lbl['permission-type-login-merchant']
        }
        if (item.key == 'permission-type-tag') {
          item.menu = this.lbl['permission-type-tag']
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.permissionTypeModal {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 800px;
  max-width: 90vw;
  min-height: 256px;
  overflow-y: scroll;
  max-height: 80vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  border-radius: 12px;
  z-index: 728;

  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 22px;
    border-bottom: solid 2px #f5f5f5;
    .header {
      font-size: 20px;
      font-weight: 500;
      margin-top: 0px;
    }
    img {
      cursor: pointer;
    }
  }
  font-size: 14px;
  font-weight: 500;
  .accessTo {
    height: 40px;
    padding: 10px;
    background-color: #f5f5f5;
  }
  .permissionList {
    height: 44px;
    padding: 12px;
    display: flex;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
}
.backDrop {
  z-index: 4;
  &.isShow {
    opacity: 1;
    visibility: visible;
  }
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
<style lang="scss">
.permission-type-modal {
  padding: 22px;
  .ant-table-thead > tr > th {
    background: #e4e6ef;
    border: 1px solid #b5b5c3;
    padding: 10px;
    text-align: left !important;
  }
  .ant-table .ant-table-tbody > tr > td {
    background: #eff2f5;
    border: 1px solid #e4e6ef;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
    height: 52px;
  }
  .ant-table .ant-table-tbody > tr > td:first-child {
    background: #e4e6ef;
    border-bottom: 1px solid #eff2f5;
  }
  .ant-table-column-title {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
